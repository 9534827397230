import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import Layout from "../../layout";
import SEO from "../../components/SEO/SEO";
import config from "../../../data/SiteConfig";
import { Divider, Button, Form, Input, InputNumber, Space, Row, Col } from "antd";
import { CSVLink } from "react-csv";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import ToolMenu from "../../components/ToolMenu";
import Toolbox from "../../images/toolbox.svg"

const ToolPage = () => {
  return (
    <Layout>
      <div className="index-container">
        <Helmet title={config.siteTitle} />
        <SEO />
        <ToolMenu/>
        <Row>
          <Col span={12} style={{
            display: `flex`,
            alignItems: 'center',
            paddingRight: '3em'
          }}>
            <p>
              This is a basic set of tools in development to use ourselves and for people generally to build DIY solar projects. Please shoot us an email if you see errors or to request other tools.
            </p>
          </Col>
          <Col span={12}>
            <img src={Toolbox} />  
          </Col>
        </Row>
      </div>
    </Layout>
  );
};

export default ToolPage;
